<template>
    <div>
        <div class="Polaris-FormLayout__Item">
            <div class="Polaris-Labelled__LabelWrapper">
                <div class="Polaris-Label">
                    <label :for="id" :id="id + 'label'" class="Polaris-Label__Text" v-text="label"> </label>
                </div>
            </div>
            <div class="Polaris-Connected">
                <div class="Polaris-Connected__Item Polaris-Connected__Item--focused Polaris-Connected__Item--primary">
                    <div
                        class="Polaris-TextField Polaris-TextField--hasValue Polaris-TextField--multiline "  :class=" invalid ? 'Polaris-TextField--error' : '' " >
                        <textarea :id="id" class="Polaris-TextField__Input" :aria-labelledby="id + 'label'"
                            :value="value" @input="$emit('input', $event.target.value)" aria-invalid="false"
                            aria-multiline="true" style="height: 108px;"> </textarea>
                        <div class="Polaris-TextField__Backdrop">

                        </div>
                        <div aria-hidden="true" class="Polaris-TextField__Resizer">
                            <div class="Polaris-TextField__DummyInput"> </div>
                            <div class="Polaris-TextField__DummyInput"> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Polaris-Labelled__Error" v-if="invalid">
                <div class="Polaris-InlineError">
                    <div class="Polaris-InlineError__Icon">
                        <span class="Polaris-Icon">
                            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z"
                                    fill-rule="evenodd"></path>
                            </svg>
                        </span>
                    </div>
                    {{ invalid }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
            },
            label: {
                type: String,
            },
            helpText: {
                type: String,
            },
            invalid: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: "text"
            }
        },
        computed: {
            id() {
                return this._uid;
            }
        }
    }
</script>

<style>

</style>